import { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    // Verifica se o gtag.js já foi inicializado para evitar adicionar o mesmo script múltiplas vezes
    if (!window.dataLayer) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16703433745';
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', 'AW-16703433745');
    }
  }, []);

  return null;
};

export default GoogleTagManager;
