import { ContactButton } from '../../components/contact-button';
import './style.css'
import { WhatsAppOutlined, MailOutlined, EnvironmentOutlined } from '@ant-design/icons';

export const Contact = ({title, sentence, phone, mail, adress,fontColorTitles='nave',fontColorParagraphs='nave'}) => {
    const createMarkup = (htmlContent) => {
        return { __html: htmlContent };
    };
    const handleClick = () => {
        window.gtag_report_conversion();
        window.fbq('track', 'Contact');
    };
    return (
        <div className='background-image'>
            <div className='box'>
            <div>
                <div>
                    <h1 className={`text-title montserrat ${fontColorTitles}-color`}>{title}</h1>
                    <div style={{fontSize: '1.1rem'}}>
                        {
                            sentence?.map(
                                (element, index) => 
                                    (
                                        <p key={index} className={`${fontColorParagraphs}-color`} dangerouslySetInnerHTML={createMarkup(element)}></p>
                                    )
                            )
                        }

                        <p>
                            <a style={{textDecoration: 'none'}} className={`${fontColorParagraphs}-color`} href="https://wa.me/5548991682656?text=Ol%C3%A1%2C%20venho%20atrav%C3%A9s%20do%20seu%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%A3o%20sobre%20a%20consulta%20em%20psicoterapia.">
                            <div id="whatasappacao" onClick={handleClick}>
                                <WhatsAppOutlined style={{ fontSize: '20px', marginBottom: '-3px', paddingRight: '8px', color: '#0d7b00'}}/>
                                {phone}
                            </div>
                        </a>
                        </p>
                        <p><MailOutlined style={{ fontSize: '20px', marginBottom: '-3px', paddingRight: '8px'}} className={`${fontColorParagraphs}-color`}/>{mail}</p>
                        <p><EnvironmentOutlined style={{ fontSize: '20px', marginBottom: '-3px', paddingRight: '8px'}} className={`${fontColorParagraphs}-color`} />{adress}</p>
                    </div>
                    <br/>
                    <div style={{paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ContactButton color={'paleta-cor1'}/>
                    </div>
                </div>
            </div>
            </div>
        </div>
      );
  };
  