import GoogleTagManager from '../Ads/GoogleTagManager';
import './style.css'
import {WhatsAppOutlined} from '@ant-design/icons'

export const Header = () => {
  const handleClick = () => {
    window.gtag_report_conversion();
    window.fbq('track', 'Contact');
    window.open('https://wa.me/5548991682656?text=Ol%C3%A1%2C%20venho%20atrav%C3%A9s%20do%20seu%20site%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%A3o%20sobre%20a%20consulta%20em%20psicoterapia.', '_blank', 'noopener,noreferrer');
};
  return (
    <div className="border">
    <header className="header">
      <div className="header-content"> 
        <div className="logo">
          <img src="/logo.png" alt="Logo" />
          <div className='namePsi white-color alex-brush'>
            <h2 style={{fontWeight: '200'}}></h2>
            <h2 style={{fontWeight: '200'}}></h2>
          </div>
        </div>
        <button onClick={handleClick} className="contact-info">
          <WhatsAppOutlined style={{ fontSize: '15px', marginBottom: '-1px' }}/> (48) 99168-2656
        </button>
      </div>
      <GoogleTagManager/>
  </header>
    </div>
  );
};