import './style.css'
import { WhatsAppOutlined } from '@ant-design/icons';

export const WhatsAppIcon = () => {

    const handleClick = () => {
        window.gtag_report_conversion();
        window.fbq('track', 'Contact');
    };

    return (
        <>
          <a href="https://wa.me/?phone=5548991682656&text=Olá,%20venho%20pelo%20site%20e%20gostaria%20de%20mais%20informações%20sobre%20a%20psicoterapia." target="_blank" rel="noopener noreferrer">
            <div id="whatasapp" onClick={handleClick}>
                <WhatsAppOutlined style={{color: '#fff', fontSize: '35px', marginBottom: '-3px' }}/>
            </div>
          </a>
       </>
      );
  };
  