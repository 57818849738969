import { ReviewCard } from '../../components/ReviewCard';
import './style.css'

export const Review = ({reviews, fontColorTitles}) => {
    const titleClassName = `text-title ${fontColorTitles}-color`;
    return (
        <div className='background-image-review'>
            <h1 style={{paddingTop: '20px'}} className={titleClassName}>Minhas Avaliações</h1>
            <div className='review-grid'>
                {reviews?.map((element, index) => (
                    <div key={index} className='review-item'> {/* Adiciona key para itens de lista */}
                        <ReviewCard review={element}/>
                    </div>
                ))}
            </div>
        </div>
    );
};
  